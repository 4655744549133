<template>
    <div class="loader-mask" :style="{'position':position}">
        <div class="loader">
            <img alt="Tixgo" src="../assets/logo.svg" width="100" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        props: {
            position: {
                type: String,
                default: 'fixed',
                required: false,
            }
        },
    };
</script>

<style scoped>
    .loader-mask{
        background-color:rgba(255,255,255,0.95);
        top:0;
        right:0;
        bottom:0;
        left:0;
        z-index:100;
    }

    .loader{
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -15px;
        animation: loading 2s infinite ease-in-out;
    }

    @keyframes loading {
        0% {
            transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        }

        100% {
            transform: perspective(120px) rotateX(0deg) rotateY(180deg);
        }
    }
</style>