import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/browser";
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
Vue.use(ToastPlugin)

Sentry.init({
  Vue,
  dsn: "https://a83f0bde2f05406193e17efb37e6a563@o4504871867514880.ingest.sentry.io/4504871869284352",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["api.tixgo.nl", "my.tixgo.nl"],
    }),
  ],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

import { createPinia, PiniaVuePlugin } from 'pinia'

import "./meta"
import "./filters"
import "./validations"
import "./fonts"
import "./layouts"
import "./boostrap"
import "./styles"
import "./axios"

Vue.config.productionTip = false

const pinia = createPinia()
Vue.use(PiniaVuePlugin)

new Vue({
  router,
  pinia,
  render: h => h(App),
}).$mount('#app')