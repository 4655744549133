<template>
    <div class="sidebar d-flex h-100" :class="isSidebarVisible() ? 'is-visible' : ''">
        <div class="main h-100">
            <b-nav vertical>
                <b-nav-item to="/manage/events">
                    <font-awesome-icon icon="ticket" class="icon fs-4" />
                    <span>Evenementen</span>
                </b-nav-item>
                <template v-if="isAdmin()">
                    <b-nav-item to="/manage/customers">
                        <font-awesome-icon icon="users" class="icon fs-4" />
                        <span>Klanten</span>
                    </b-nav-item>
                    <b-nav-item to="/manage/organisation">
                        <font-awesome-icon icon="building" class="icon fs-4" />
                        <span>Organisatie</span>
                    </b-nav-item>
                    <b-nav-item to="/manage/support">
                        <font-awesome-icon icon="question" class="icon fs-4" />
                        <span>Support</span>
                    </b-nav-item>
                </template>
                <b-nav-item to="/account/logout">
                    <font-awesome-icon icon="sign-out" class="icon fs-4" />
                    <span>Uitloggen</span>
                </b-nav-item>
            </b-nav>
        </div>
        <div class="sub h-100">
            <template v-if="$route.path.includes('manage/events')">
                <template v-if="$route.params.id">
                    <!--
                    <div class="event">
                        <div>Test 2</div>
                        <div>6 jul - 4 nov 2023</div>
                    </div>
                    -->
                    <b-nav vertical>
                        <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/dashboard'">
                            Dashboard
                        </b-nav-item>
                        <template v-if="isAdmin() || isManager()">
                            <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/stats'">
                                Statistieken
                            </b-nav-item>
                        </template>
                        <template v-if="isAdmin() || isManager() || isService()">
                            <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/orders'">
                                Bestellingen
                            </b-nav-item>
                            <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/guests'">
                                Gasten
                            </b-nav-item>
                        </template>
                        <template v-if="isAdmin() || isManager()">
                            <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/settings'">
                                Instellingen
                            </b-nav-item>
                        </template>
                        <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/entrance'">
                            Toegangscontrole
                        </b-nav-item>
                        <template v-if="isAdmin() || isManager()">
                            <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/invites'">
                                Uitnodigingen
                            </b-nav-item>
                        </template>
                        <template v-if="isAdmin() || isManager()">
                            <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/notifications'">
                                Bezoekers informeren
                            </b-nav-item>
                            <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/financial'">
                                Financieel
                            </b-nav-item>
                            <b-nav-item @click="foldSidebar" :to="'/manage/events/' + $route.params.id + '/log'">
                                Logboek
                            </b-nav-item>
                        </template>
                    </b-nav>
                </template>
            </template>
            <template v-if="$route.path.includes('manage/customers')">
                <b-nav vertical>
                    <b-nav-item @click="foldSidebar" :to="'/manage/customers'">
                        Alle klanten
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/customers'">
                        Mijn klanten
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/customers'">
                        Instellingen
                    </b-nav-item>
                </b-nav>
            </template>
            <template v-if="$route.path.includes('manage/organisation')">
                <b-nav vertical>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/edit'">
                        Gegevens
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/profile'">
                        Profiel
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/payments'">
                        Betalingen
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/locations'">
                        Locaties
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/webshop'">
                        Webshop
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/links'">
                        Koppelingen
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/team'">
                        Team
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/financial'">
                        Financieel
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/invoices'">
                        Facturen
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/exports'">
                        Exporteren
                    </b-nav-item>
                    <b-nav-item @click="foldSidebar" :to="'/manage/organisation/balance'">
                        Saldo
                    </b-nav-item>
                </b-nav>
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useShopStore } from '@/stores/shop'

export default {
    data() {
        return {
            event: {}
        }
    },
    methods: {
        ...mapActions(useShopStore, ['isSidebarVisible', 'foldSidebar', 'isAdmin', 'isManager', 'isService', 'isScanner']),
    },
}
</script>

<style scoped>
    .sidebar{
        border-bottom:1px solid #eee;
        margin-left:-250px;
        transition:all ease-in-out 0.3s;
    }
    .sidebar.is-visible{
        margin-left:0;
    }
    @media screen and (min-width:992px) {
        .sidebar{
            margin-left:0;
        }
    }
    .event{
        padding:0.5rem 1rem;
        border-bottom:1px solid #eee;
    }
    .sub{
        border-left:1px solid #eee;
    }
    .sub .nav-link{
        min-width:190px;
    }
    .nav-item a{
        color:#CA355B;
    }
    .main .nav-item{
        min-height:40px;
        text-align:center;
        position:relative;
        border-bottom:1px solid #eee;
    }
    .main .nav-link span{
        position:absolute;
        z-index:1;
        background-color:#fff;
        padding:0.6rem 10px 0.6rem 60px;
        top:0;
        left:0;
        display:none;
        box-shadow:0 0 0 2px #eee;
        box-shadow: 0px 0px 5px 1px #eee;
        border-radius: 0 10px 10px 0;
    }
    .main .nav-link:hover span{
        display:block !important;
    }
    .main .icon{
        position:relative;
        z-index:2;
    }
    .router-link-active{
        background-color:#eee;
    }
</style>