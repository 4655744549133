<template>
    <div style="background-color:#F2F5F7;">
        <Navbar />
        <div class="d-flex">
            <div class="sidenav">
                <Sidebar />
            </div>
            <div class="flex-grow-1 d-relative">
                <Loader position="absolute" v-if="getLoading()" />
                <router-view />
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'

    import Navbar from '@/components/Navbar'
    import Footer from '@/components/Footer'
    import Loader from '@/components/Loader'
    import Sidebar from '@/components/Sidebar'

    export default {
        metaInfo: {
            titleTemplate: '%s | Tixgo'
        },
        components: {
            Navbar,
            Footer,
            Loader,
            Sidebar,
        },
        methods: {
            ...mapActions(useShopStore, ['getLoading']),
        }
    }
</script>

<style scoped>
.sidenav{
    background-color:#fff;
    position:relative;
    z-index:100;
}
.d-relative{
    position:relative;
}
</style>