<template>
    <div>
        <b-navbar class="main-navbar" toggleable="lg" type="dark" style="background-color:#CA355B;">
            <b-button @click="toggleSidebar" class="navbar-toggler mr-3">
                <span class="navbar-toggler-icon"></span>
            </b-button>
            
            <b-navbar-brand to="/manage" class="p-0">
                <img alt="Tixgo" src="../assets/logo_white.svg" width="110" />
            </b-navbar-brand>

            <template v-if="isAdmin()">
                <div class="ml-auto">
                    <b-button variant="outline-light" to="/account">
                        <font-awesome-icon icon="user" />
                    </b-button> 
                </div>
            </template>

            <!--
            <b-collapse id="main-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <template v-if="isLoggedIn()">
                        <b-nav-item class="mt-1 mr-lg-2" to="/manage/events">Evenementen</b-nav-item>
                        <b-nav-item class="mt-1 mr-lg-2" to="/manage/customers">Klanten</b-nav-item>
                        <b-nav-item class="mt-1 mr-lg-2" to="/manage/organisation">Organisatie</b-nav-item>
                        <b-nav-item class="mt-1 mr-lg-2" to="/manage/support">Support</b-nav-item>
                        <b-nav-item class="mt-1 mr-lg-2" to="/account/logout">Uitloggen</b-nav-item>
                    </template>
                    <b-nav-item to="/account" v-b-tooltip.hover title="Mijn account">
                        <font-awesome-icon icon="fa-solid fa-user" class="btn-icon text-primary d-none d-lg-block"  />
                        <span class="d-lg-none">Mijn account</span>
                    </b-nav-item>
                    <!-/-
                    <b-nav-item class="d-none d-lg-block" to="/manage" v-b-tooltip.hover title="Controlepaneel">
                        <font-awesome-icon icon="fa-solid fa-cog" class="btn-icon text-primary d-none d-lg-block" />
                        <span class="d-lg-none">Controlepaneel</span>
                    </b-nav-item>
                    -/->
                    <!-/-
                    <b-nav-item to="/basket" v-b-tooltip.hover title="Winkelmandje">
                        <font-awesome-icon icon="fa-solid fa-basket-shopping" class="btn-icon text-primary d-none d-lg-block"  />
                        <span class="d-lg-none">Winkelmandje</span>
                    </b-nav-item>
                    -/->
                </b-navbar-nav>
            </b-collapse>
            -->
        </b-navbar>
    </div>
</template>

<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'

    export default {
        methods: {
            ...mapActions(useShopStore, ['toggleSidebar', 'isAdmin']),
            isLoggedIn: function() {
                if (localStorage.getItem('token')) {
                    return true;
                }
                
                return false;
            },
        },
    }
</script>

<style scoped>
.main-navbar a.nav-link{
    color:#fff;
}
</style>