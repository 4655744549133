import Vue from 'vue'
import moment from 'moment'

moment.locale('nl');

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY');
  }
});

Vue.filter('formatMonthDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMM');
  }
});

Vue.filter('formatDayDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD');
  }
});

Vue.filter('formatDayNameDate', function(value) {
  if (value) {
    return moment(String(value)).format('ddd');
  }
});

Vue.filter('formatYear', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY');
  }
});

Vue.filter('formatTextDate', function(value) {
  if (value) {
    return moment(String(value)).format('ddd DD MMMM YYYY');
  }
});

Vue.filter('formatTextDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('ddd DD MMMM YYYY HH:mm');
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm');
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY HH:mm');
  }
});

Vue.filter('toLowerCase', function(value) {
  if (value) {
    return String(value).toLowerCase();
  }
});

Vue.filter('toUpperCase', function(value) {
  if (value) {
      return String(value).toUpperCase();
  }
});

Vue.filter('toCapitalize', function(value) {
  if (value) {
      return String(value).charAt(0).toUpperCase() + String(value).slice(1);
  }
});

Vue.filter('toCurrency', function(value) {
  var formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR'
  });
  return formatter.format(value);
});

Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})