import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      to;
      return 'manage'
    }
  },
  {
    path: '/manage',
    name: 'Manage',
    meta: { layout: 'portal', requiresAuth: true },
    redirect: to => {
      to;
      return '/manage/events'
    }
  },
  {
    path: '/manage/events',
    meta: { layout: 'portal', requiresAuth: true },
    component: () => import( '../views/Events.vue'),
    children: [
      {
        path: '',
        component: () => import( '../views/events/Index.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'archive',
        component: () => import( '../views/events/Archive.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'create',
        component: () => import( '../views/events/Create.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: ':id',
        meta: { layout: 'portal', requiresAuth: true },
        component: () => import( '../views/events/Detail.vue'),
        redirect: to => {
          to;
          return '/manage/events/:id/dashboard'
        },
        children: [
          {
            path: 'dashboard',
            component: () => import( '../views/events/details/Index.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'stats',
            component: () => import( '../views/events/details/Stats.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'orders',
            component: () => import( '../views/events/details/Orders.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'orders/tickets',
            component: () => import( '../views/events/details/Tickets.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'guests',
            component: () => import( '../views/events/details/guests/Index.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'guests/create',
            component: () => import( '../views/events/details/guests/Create.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'entrance',
            component: () => import( '../views/events/details/Entrance.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'scanner',
            component: () => import( '../views/events/details/Scanner.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'notifications',
            component: () => import( '../views/events/details/Notifications.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'notifications/create',
            component: () => import( '../views/events/details/notifications/Create.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'invites',
            component: () => import( '../views/events/details/Invites.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'financial',
            component: () => import( '../views/events/details/Financial.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'log',
            component: () => import( '../views/events/details/Log.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'duplicate',
            component: () => import( '../views/events/details/Duplicate.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'publish',
            component: () => import( '../views/events/details/Publish.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'settings',
            component: () => import( '../views/events/details/Settings.vue'),
            meta: { layout: 'portal', requiresAuth: true },
            children: [
              {
                path: '',
                component: () => import( '../views/events/details/settings/Index.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'event',
                component: () => import( '../views/events/details/settings/Event.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'tickets',
                component: () => import( '../views/events/details/settings/Tickets.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'tickets/:ticket/edit',
                component: () => import( '../views/events/details/settings/TicketsEdit.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'theme',
                component: () => import( '../views/events/details/settings/Theme.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'emails',
                component: () => import( '../views/events/details/settings/Emails.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'personal-data',
                component: () => import( '../views/events/details/settings/Personal.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'coupons',
                component: () => import( '../views/events/details/settings/Coupons.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'invites',
                component: () => import( '../views/events/details/settings/Invites.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'links',
                component: () => import( '../views/events/details/settings/Links.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
              {
                path: 'webshop',
                component: () => import( '../views/events/details/settings/Webshop.vue'),
                meta: { layout: 'portal', requiresAuth: true },
              },
            ]
          },
        ]
      },
    ]
  },
  {
    path: '/manage/orders',
    meta: { layout: 'portal', requiresAuth: true },
    component: () => import( '../views/Orders.vue'),
    children: [
      {
        path: '',
        component: () => import( '../views/orders/Index.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: ':id',
        component: () => import( '../views/orders/Detail.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
    ]
  },
  {
    path: '/manage/organisation',
    component: () => import( '../views/Organisation.vue'),
    meta: { layout: 'portal', requiresAuth: true },
    children: [
      {
        path: '',
        component: () => import( '../views/organisation/Index.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'edit',
        component: () => import( '../views/organisation/Edit.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'profile',
        component: () => import( '../views/organisation/Profile.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'financial',
        component: () => import( '../views/organisation/Financial.vue'),
        meta: { layout: 'portal', requiresAuth: true }
      },
      {
        path: 'locations',
        component: () => import( '../views/organisation/Locations.vue'),
        meta: { layout: 'portal', requiresAuth: true },
        children: [
          {
            path: '',
            component: () => import( '../views/organisation/locations/Index.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'create',
            component: () => import( '../views/organisation/locations/Create.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'edit/:id',
            component: () => import( '../views/organisation/locations/Edit.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
        ]
      },
      {
        path: 'webshop',
        component: () => import( '../views/organisation/Webshop.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'links',
        component: () => import( '../views/organisation/Links.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'team',
        component: () => import( '../views/organisation/Team.vue'),
        meta: { layout: 'portal', requiresAuth: true },
        children: [
          {
            path: '',
            component: () => import( '../views/organisation/team/Index.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'create',
            component: () => import( '../views/organisation/team/Create.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'edit/:id',
            component: () => import( '../views/organisation/team/Edit.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
        ]
      },
      {
        path: 'invoices',
        component: () => import( '../views/organisation/Invoices.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'exports',
        component: () => import( '../views/organisation/Exports.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'payments',
        component: () => import( '../views/organisation/Payments.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'balance',
        component: () => import( '../views/organisation/Balance.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
      {
        path: 'setup',
        component: () => import( '../views/Organisation.vue'),
        meta: { layout: 'portal', requiresAuth: true },
        children: [
          {
            path: 'payment',
            component: () => import( '../views/organisation/setup/Payment.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'details',
            component: () => import( '../views/organisation/setup/Details.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'bank',
            component: () => import( '../views/organisation/setup/Bank.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
          {
            path: 'documents',
            component: () => import( '../views/organisation/setup/Documents.vue'),
            meta: { layout: 'portal', requiresAuth: true },
          },
        ]
      }
    ],
  },
  {
    path: '/manage/customers',
    meta: { layout: 'portal', requiresAuth: true },
    component: () => import( '../views/Customers.vue'),
    children: [
      {
        path: '',
        component: () => import( '../views/customers/Index.vue'),
        meta: { layout: 'portal', requiresAuth: true },
      },
    ]
  },
  {
    path: '/manage/support',
    name: 'Support',
    meta: { layout: 'portal', requiresAuth: true },
    component: () => import( '../views/Support.vue')
  },
  {
    path: '/manage/support/new',
    name: 'SupportCreate',
    meta: { layout: 'portal', requiresAuth: true },
    component: () => import( '../views/SupportCreate.vue')
  },
  {
    path: '/manage/support/item/:id',
    name: 'SupportItem',
    meta: { layout: 'portal', requiresAuth: true },
    component: () => import( '../views/SupportItem.vue')
  },
  {
    path: '/account',
    name: 'Account',
    meta: { layout: 'portal', requiresAuth: true },
    component: () => import( '../views/Account.vue')
  },
  {
    path: '/account/register',
    name: 'AccountRegister',
    meta: { layout: 'login', requiresAuth: false },
    component: () => import( '../views/AccountRegister.vue')
  },
  {
    path: '/account/verify',
    name: 'AccountVerify',
    meta: { layout: 'login', requiresAuth: false },
    component: () => import( '../views/AccountVerify.vue')
  },
  {
    path: '/account/login',
    name: 'AccountLogin',
    meta: { layout: 'login', requiresAuth: false },
    component: () => import( '../views/AccountLogin.vue')
  },
  {
    path: '/account/recovery',
    name: 'AccountRecovery',
    meta: { layout: 'login', requiresAuth: false },
    component: () => import( '../views/AccountRecovery.vue')
  },
  {
    path: '/account/reset',
    name: 'AccountReset',
    meta: { layout: 'login', requiresAuth: false },
    component: () => import( '../views/AccountReset.vue')
  },
  {
    path: '/account/logout',
    name: 'AccountLogout',
    component: {
      beforeRouteEnter(to, from, next) {
        to;
        from;
        localStorage.removeItem('type');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        return next('/account/login');
      }
    }
  },
  {
    path: '*',
    name: 'NotFound',
    meta: { layout: 'web', requiresAuth: false },
    component: () => import( '../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }  
})

router.beforeEach((to, from, next) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {

    let check_url = "https://api.tixgo.nl/accounts/check";

    axios.post(check_url, { }, {
      headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
    }).then(response => { 
      if (response.data.status != 'success') {
        localStorage.removeItem('type');
        localStorage.removeItem('role');
        localStorage.removeItem('token');

        return next('/account/login');
      } else {
        next();
      }
    });
  } else {
    // Within else, because else route is forwarded first always.
    next();
  }
})

router.onError(error => {
  if (confirm('An update is available or a network error occurred. Do you want to reload?')) {
    window.location.reload();
    error;
  }
})

export default router