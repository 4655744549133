import { defineStore } from 'pinia'

export const useShopStore = defineStore('counter', {
  state: () => ({
    shop: {
      config: {},
      theme: {},
      organisation: {},
      events: [],
      slots: [],
    },
    isLoading: false,
    sidebar_visible: false,
  }),
  actions: {
    setShop(shop) {
      this.shop = shop;
    },
    getShop() {
        return this.shop;
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    getLoading() {
        return this.isLoading;
    },
    toggleSidebar() {
      if (this.sidebar_visible) {
        this.sidebar_visible = false;
      } else {
        this.sidebar_visible = true;
      }
    },
    foldSidebar() {
      this.sidebar_visible = false;
    },
    isSidebarVisible() {
      return this.sidebar_visible;
    },
    isAdmin() {
      return (localStorage.getItem('role') == 'administrator' ? true : false);
    },
    isManager() {
      return (localStorage.getItem('role') == 'manager' ? true : false);
    },
    isService() {
      return (localStorage.getItem('role') == 'service' ? true : false);
    },
    isScanner() {
      return (localStorage.getItem('role') == 'scanner' ? true : false);
    }
  }
})