<template>
    <div class="bg-login">
        <router-view />
    </div>
</template>

<style scoped>
    .bg-login{
        background-image:url('../assets/login_bg.png');
        background-size:cover;
        background-position:50% 100%;
        height:100%;
        min-height:100vh;
    }
</style>

<script>
    export default {
        metaInfo: {
            titleTemplate: '%s | Tixgo'
        },
        components: {},
        methods: {}
    }
</script>