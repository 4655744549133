import Vue from 'vue'
import Axios from 'axios'

Axios.defaults.headers.common = {
  'Authorization': `Bearer ${localStorage.getItem('token')}`,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

Vue.prototype.$axios = Axios;